import {
  faMugHot,
  faEllipsis,
  faUserTie,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import werknemerImage from "./images/kandidaten.jpg";
import werkgeverImage from "./images/werkgever.jpg";
import { VoorWerknemers } from "./components/VoorWerknemers";
import { VoorWerkgevers } from "./components/VoorWerkgevers";

export const reCAPTCHAKey = "6Lcj6kUaAAAAAJfhzrZdbSkeN-9d-4X4Md0VM01c";
export const baseUrl =
  "https://rp-one-contact-service-2tky8.ondigitalocean.app/api";

export const companyValues = [
  {
    title: "Kwaliteit boven kwantiteit",
    text: "Het leveren van de ideale versterking voor uw project is ons doel. Consultants en partners worden begeleid gedurende het gehele project en zelfs daarna.",
  },
  {
    title: "Langetermijnvisie",
    text: "Wij beschouwen onze consultants en partners als familie. Na onze interventie stopt het werk niet, integendeel.",
  },
  {
    title: "Opleiding",
    text: "Al onze consultants worden voorzien van een continue, professionele opleiding zodat zij hun job naar behoren kunnen uitoefenen. Ook onze niet-technische collega’s worden regelmatig bijgeschoold.",
  },
  {
    title: "Respect en integriteit",
    text: "Wij hechten enorm veel belang aan het respecteren van de wensen van al onze stakeholders. Alles gebeurt op een discrete manier met oog voor de geldende privacyregels.",
  },
];

export const jobs = [
  {
    title: ".NET",
    icon: ".NET",
    id: "net",
    openings: [
      {
        title: ".Net Developer",
        place: "Vlaanderen - Brussel",
        id: "net-developer",
        jobDescription: [
          "Jouw rol zal zijn om deel te nemen aan onze bestaande projectteams, deze te ondersteunen of te leiden en hen te helpen om het project om te zetten in een technisch succes door het gewenste eindresultaat voor de klant te produceren.",
          "Op basis van je Career Development Path, je wensen, je interesses en je expertiseniveau, kun je deelnemen aan een project van A tot Z, dat wil zeggen vanaf het Blueprint / Audit niveau tot de implementatie van de voorgestelde oplossing.",
        ],
        jobRequirements: [
          "U bent afgestudeerd als Bachelor of Master in IT of een aanverwante richting.",
          "U hebt een passie voor programmeren binnen het .NET framework. Je wilt je kennis uitbreiden van C#, .NET Core, Azure en andere .NET gerelateerde frameworks zoals Entity Framework, Xamarin.Forms etc.",
          "Hou je van afwisseling en wil je ervaring opdoen in verschillende projecten binnen toonaangevende, innovatieve bedrijven.",
          "Als gezicht van RP One bij de klant help je mee om de relatie met de klant te versterken.",
          "Je bent gepassioneerd door technologie en volgt de laatste trends.",
          "Clean coding en andere best practices van professioneel software vakmanschap maken deel uit van je dagelijkse job routine en je zoekt naar duo's en mentors die er net zo over denken en die hun ervaring met jou delen.",
          "Je spreekt Nederlands en/of Frans en je kunt je vloeiend uitdrukken in het Engels.",
        ],
        jobBenefits: [
          "Een inspirerende werkomgeving met leuke collega's met een sterke focus op sfeer en teamspirit.",
          "Een persoonlijk carrièreplan en transparant opleidingsprogramma dat regelmatig besproken wordt met onze talent manager en dat je voorbereidt op de uitdaging van een sleutelpositie binnen onze klantenorganisatie.",
          "Een goede work-life balance met thuiswerk mogelijkheden.",
          "Een full-option loonpakket, inclusief tal van extralegale voordelen, zoals verzekeringen, elektronische toestellen, netto vergoedingen, extra vakantiedagen, enz.",
          "Een premium bedrijfswagenpark in slimme combinatie met andere duurzame faciliteiten.",
        ],
      }
    ]
  },
  {
    title: "Java",
    icon: faMugHot,
    id: "java",
    openings: [
      {
        title: "Java Developer",
        place: "Vlaanderen - Brussel",
        id: "java-developer",
        jobDescription: [
          "Jouw rol zal zijn om deel te nemen aan onze bestaande projectteams, deze te ondersteunen of te leiden en hen te helpen om het project om te zetten in een technisch succes door het gewenste eindresultaat voor de klant te produceren.",
          "Op basis van je Career Development Path, je wensen, je interesses en je expertiseniveau, kun je deelnemen aan een project van A tot Z, dat wil zeggen vanaf het Blueprint / Audit niveau tot de implementatie van de voorgestelde oplossing.",
        ],
        jobRequirements: [
          "Je hebt praktische ervaring met een aantal van onderstaande Java-technologieën",
          "U bent afgestudeerd als Bachelor of Master in IT of een aanverwante richting.",
          "Je hebt een passie voor ontwikkelen in Java en je hebt zin om je te verdiepen in Spring (Boot) frameworks en aanverwante Java tools?",
          "Je houdt van afwisseling en wil graag ervaring opdoen in verschillende projecten binnen toonaangevende, innovatieve bedrijven.",
          "Als gezicht van RP One bij de klant help je mee om de relatie met de klant te versterken.",
          "Je bent gepassioneerd door technologie en volgt de laatste trends.",
          "Clean coding en andere best practices van professioneel software vakmanschap maken deel uit van je dagelijkse job routine en je zoekt duo's en mentors die er net zo over denken en die hun ervaring met jou delen.",
          "Je spreekt Nederlands en/of Frans en je kan je vlot uitdrukken in het Engels.",
        ],
        jobBenefits: [
          "Een inspirerende werkomgeving met leuke collega's met een sterke focus op sfeer en teamspirit.",
          "Een persoonlijk carrièreplan en transparant opleidingsprogramma dat regelmatig besproken wordt met onze talent manager en dat je voorbereidt op de uitdaging van een sleutelpositie binnen onze klantenorganisatie.",
          "Een goede work-life balance met thuiswerk mogelijkheden.",
          "Een full-option loonpakket, inclusief tal van extralegale voordelen, zoals verzekeringen, elektronische toestellen, netto vergoedingen, extra vakantiedagen, enz.",
          "Een premium bedrijfswagenpark in slimme combinatie met andere duurzame faciliteiten.",
        ],
      },
    ],
  },
  {
    title: "other",
    icon: faEllipsis,
    id: "other",
    openings: [
      {
        title: "Recruitment Officer",
        place: "Vilvoorde",
        id: "recruitment-officer",
        icon: faEllipsis,
        jobDescription: [
          "Understand the business recruitment needs.",
          "Identify and use the relevant channel for finding the right candidates.",
          "Extend your network in your technology domain of recruitment, candidate hunting and improve the companies visibility.",
          "Pre-Selection screening and candidate presentation.",
          "Participate to projects (employer branding, student fairs, recruitment campaigns).",
          "Ownership of the companies Social Media and Marketing strategy.",
        ],
        jobRequirements: [
          "First of all, the aspect of interviewing, listening to peoples stories and detecting their ambitions is not a duty but rather your second nature.",
          "Furthermore, you are graduated as a Bachelor or Master in the fields of business, marketing, communication.",
          "You have a strong affinity or first experience in professional use of Social Media.",
          "You are a strong communicator and you think out of the box.",
          "You have organizational skills and stress resistance to ensure that you achieve concrete results in an efficient manner.",
          "You work autonomously and know how to motivate others to go for a goal together.",
          "You are fluent in Dutch, French with a strong command of English.",
          "You have a driving license B.",
        ],
        jobBenefits: [
          "A fascinating position where you can work in a very independent and organized manner.",
          "Dynamic and fast-growing organization that offers development and career opportunities to young, driven people with entrepreneurial mindset according to your personal interest and affinities.",
          "Competitive salary package, supplemented with a premium company car and fringe benefits including an attractive commission system.",
        ],
      },
      {
        title: "Spontane Sollicitatie",
        place: "Belgium",
        id: "spontane-sollicitatie",
        icon: faEllipsis,
        jobDescription: [
          "Niets dat je op het lijf geschreven is, maar wel interesse in wat we doen? Solliciteer dan spontaan want bij RP One geloven we in bouwen aan je eigen pad!",
          "Bezorg ons je meest recente cv en één van onze recruiters neemt contact met u op om uw verwachtingen te begrijpen en u te helpen bij uw zoektocht naar een nieuwe baan.",
        ],
      },
    ],
  },
];

export const solicitationProcedure = [
  {
    title: "screening",
    text: "We doen een screening van je CV. Hierna bellen je op om te luisteren hoe we jou gaan kunnen helpen in je loopbaan. Zien we een match dan nodigen we je graag uit voor een eerste gesprek.",
    id: 1,
  },
  {
    title: "interview partner",
    text: "We bespreken dit met onze partner(s). Zien zij ook een match dan bereiden we jou voor op je eerste interview met onze partner. Is dit interview positief dan kan er een bijkomend interview gepland worden naargelang de wensen van onze partner.",
    id: 2,
  },
  {
    title: "Nieuwe baan!",
    text: "Is je finale interview positief, kloppen je referenties en is er een deal over de voorwaarden dan wensen we jou proficiat met je nieuwe baan!",
    id: 3,
  },
];

export const hoeWijWerken = [
  {
    title: "Voor werknemers",
    icon: faUser,
    id: 1,
    image: werknemerImage,
    content: <VoorWerknemers />,
  },
  {
    title: "Voor werkgevers",
    icon: faUserTie,
    id: 2,
    image: werkgeverImage,
    content: <VoorWerkgevers />,
  },
];
